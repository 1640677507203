<template>
  <v-container fluid>
    <!--Breadcrumbs--> 
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>

    <data-table 
      :headers="headers"
      :data="data"
      :loading="loading"
      :pageNumber="pageNumber"
      :paginate="paginate"
      :options="options"
      >
        <component :is="headerChildComponent" :refreshData="handleRefreshData"></component>
      </data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Breadcrumbs from '../components/common/Breadcrumbs.vue';
import DataTable from '../components/common/DataTable.vue';
import DataTableHeader from '../components/common/DataTableHeader.vue';
import { itemsSpan } from '../helpers/index';
export default {
  components:{
    Breadcrumbs,
    DataTable,
    DataTableHeader
  },
  data(){
    return{
        breadcrumbs: [
            {
                text: 'Dashboard',
                href: '/',
            },
            {
                text: 'Reports',
                href: '/reports',
            },
        ],
        headers: [
            { text: 'Created at', value: 'created' },
            { text: 'Report keyword', value: 'keyword' },
            { text: 'Country', value: 'country' },
            { text: 'Language', value: 'language' },
            { text: 'Status', value: 'status' },
        ],
        headerChildComponent: 'DataTableHeader',
        options: { 
          statusChips: ['status'], 
          timestamps: [ 'created' ]
        }
    }
  },
  computed: {
    ...mapState('reports', ['data', 'pageNumber', 'loading']),
    itemsSpan,
    plansPageNumber: {
        get () {
            return this.pageNumber
        },
        set (value) {
            this.UPDATE_PAGE_NUMBER(value)
        }
    }
  },
  methods:{
    ...mapActions('reports', ['getReports']),
    ...mapMutations('reports', ['UPDATE_PAGE_NUMBER']),
    paginate(pageNumber){
      //console.log(val)

      this.plansPageNumber = pageNumber

      this.getReports();

    },
    handleRefreshData(){
      this.UPDATE_PAGE_NUMBER(1)
      this.getReports()
    },
  },
  created(){
    this.getReports()
  }
}
</script>

<style scoped>
.status-chip{
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: 120px;
}
.breadcrumb-link{
        color: #0000008a !important;
    }
</style>